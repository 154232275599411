
@font-face {
  font-family: "NeueHelvetica45Light";
  font-weight: 200;
  src: url('./static/fonts/NeueHelvetica45Light/font.woff'),  url('./static/fonts/NeueHelvetica45Light/font.woff2');
}

@font-face {
  font-family: "NeueHelvetica65Medium";
  font-weight: 500;
  src: url('./static/fonts/NeueHelvetica65Medium/font.woff'), url('./static/fonts/NeueHelvetica65Medium/font.woff2');
}

@font-face {
  font-family: "ChronicleDisp-Bold";
  font-weight: 700;
  src: url('./static/fonts/ChronicleDisp-Bold.otf');
}

:root {
  --grey: #5a5a5a;
  --grey0: (0,100,200);
  --black: rgb(0,0,0);
  --white: rgb(255,255,255);
  --vermilion: rgb(210, 69, 30);
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-theme-color: var(--vermilion) !important;
}

::selection {
  color: auto;
  background: none;
}

::-webkit-scrollbar {
  display: none;
}

@-moz-document url-prefix() {
  html,body{scrollbar-width: none; }
}

svg.on {
  
}

svg.off {
  display: none;
}

body {
  color: var(--black);
  /* font-family: "Gotham A", "Gotham B", sans-serif; */
  font-family: 'NeueHelvetica45Light', sans-serif;
  font-weight: 200;
  overflow-x: hidden;
  background: white;
  padding: 0;
  margin: 0;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
}

.animTrigger1 {
  height: 0;
}


#top.none.navSm  {
  background: rgba(16, 41, 76,0.2);
  backdrop-filter: blur(20px);
}

.white {
  background: white;
  color: var(--dark-blue);
}

.white a,
.white a:visited,
.white h2 {
  color: var(--dark-blue);
}

.app {
  width: 100%;
  height: 100%;
  float: left;
  padding: 0 0;
}

.app.menuOn {
  /* overflow: hidden; */
  /* height: 100vh; */
  /* overflow: hidden;  */
}

.app.menuOn #logo {
  position: fixed;
}

.page {
  float: left;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

#core {
  width: 100%;
  min-height: 60vh;
  position: relative;
  z-index: 1;
  background: white;
  float: left;
  margin-bottom: 50vh;
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.4); */

}


footer {
  position: fixed;
  bottom: 0;
  width: 92vw;
  min-height: 50vh;
  padding: 0 4vw 0 4vw;
  background: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 50px;
  color: #fff;
}


footer h2, footer h3 {
  color: var(--white);
}

footer .contactInfo {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 50px;
}

.copyright {
  padding-top: 20px;
}



.copyright a, footer a, footer .contactInfo a {
  color: #fff;
}

::-webkit-scrollbar {
  display: none;
}

h1, h1 p {
  font-size: clamp(16px,3.75vw, 75px);
  margin: 0;
  padding: 0;
  
  font-weight: 500;
  font-family: "NeueHelvetica65Medium";
  color: var(--black);
}



h2, h2 p {
  font-size: clamp(16px,3.75vw, 75px);
  color: var(--black);
  /* letter-spacing: 5px; */
  /* letter-spacing: -20px; */
  margin: 0 0 0 0;
  padding: 0;
  font-weight: 500;
  line-height: 100%;
  font-family: "NeueHelvetica65Medium";
  /* font-family: "NeueHelvetica65Medium"; */
  /* font-family: "Gotham", sans-serif; */
}

.textBlock h2 p, .textBlock h2, #aboutPage h1, #aboutPage h1 p, .caseIndex h1, .caseIndex h1 p, .relatedWork h3 {
  line-height: 120%;
  font-family: "ChronicleDisp-Bold";
  letter-spacing: 0;
}

.caseIndexBox h2, .contactInfo h1, footer h2, .relatedWork h3 {
  font-size: clamp(28px,3.75vw, 50px);
}

#aboutPage h2 {
  padding-bottom: 20px;
}

#aboutPage h2#principleHeading_0 {
  padding-bottom: 0;
}

#aboutPage h2.pad0 {
  padding-bottom: 0;
}

.aboutBanner {
  padding-top: 6.5vw;

}



.caseIndexBox h2{
  color: var(--white)
}

.context {
  color: #fff;
  font-weight: 700;
  letter-spacing: 3px;
}

h3, h3 p, .contactInfo h3, #caseContent .textColumn h2, #caseContent .textColumn h2 p, #aboutPage .joinRow h3, #aboutPage .joinRow h3 p, .quoteSlide p {
  font-size: clamp(20px, 1.458vw, 28px);
  font-weight: 200;
  margin: 10px 0 10px 0;
  padding: 0;
  line-height: 120%;
  font-family: "NeueHelvetica45Light", sans-serif;
  letter-spacing: 1px;
}

#aboutPage h3 {
  font-weight: 500;
  margin: 0;
  font-family: "NeueHelvetica65Regular", sans-serif;
  max-width: 60vw;
}

#aboutPage h3 p {
  /* padding: 0;
  margin: 0; */
  text-align: left;
}



strong {
  font-family: "NeueHelvetica65Regular", sans-serif;
}

p, h4, .contactInfo h2, .quoteSlide p.sm, .joinRow p {
  line-height: 150%;
  font-size: clamp(14px, 1.125vw, 18px);
  font-weight: 200;
  font-family: "NeueHelvetica45Light";
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
}


.joinRow p {
  font-weight: 700;
  font-family: "NeueHelvetica65Regular", sans-serif;
}

#bioPage h2 {
  font-size: clamp(14px, 1.125vw, 18px);
  font-weight: 500;
  font-family: "NeueHelvetica65Regular", sans-serif;
}

#bioPage p {
  margin: 0 0 20px 0;
}

.bioRow {
    padding-bottom: 100px;
}

.joinRow a, .joinRow a:visited, .backBtn, .backBtn:visited {
  color: var(--vermilion);
  font-weight: 500;
  font-family: "NeueHelvetica65Regular", sans-serif;
  display: flex;
  gap: 10px;
  align-items: center;
}


.bioRow h4 {
  margin: 0px 0 0 0;
  padding: 0;
  font-weight: 700;
  text-align: left;
  /* text-transform: uppercase; */
  font-family: "NeueHelvetica65Regular", sans-serif;
  text-align: center;
  width: 100%;
}


.bioRow p{
  margin: 0;
  padding: 0;
  text-align: left;
  text-align: center;
  width: 100%;
  /* text-transform: uppercase; */
}

.bioRow .learn p {
  text-transform: none;
}

.bioRow .grid .item {
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.bioRow .imageBox {
  aspect-ratio: 1/1;
  overflow: hidden;
  object-fit: cover;

}

.imageBox .overlay {
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  float: left;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imageBox .overlay:hover {
  opacity: 1;
}

.imageBox .overlay a {
  color: var(--white);
}

.overlayInner {
  max-width: 80%;

}

.overlayInner .learn {
  justify-content: space-between;
}


.bioRow .grid {
  width: 100%;
}

.bioRow .grid .item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  float: left;

}


a,
a:visited {
  color: var(--black);
  text-decoration: none;
}



/* Transitions */
/* 
.page {
  opacity: 0;
} */

/* #content {
  opacity: 0;
} */
.pageItem {
  opacity: 0;
}

.pageItem-enter {
  opacity: 0;
}

.pageItem-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.pageItem-exit,
.pageItem-done,
.subPageItem-enter-done {
  opacity: 1;
}

.subPageItem-enter {
  opacity: 0;
}

.subPageItem-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.subPageItem-exit,
.subPageItem-enter-done {
  opacity: 1;
}

.blue, .blue a {
  color: var(--black);
}

.blue path {
  stroke: var(--black);
}

/* Page */

#top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 3;
  height: 58px;
  /* background: rgba(0,0,0,0.8); */
}

.logo {
  position: fixed;
  top: 40px;
  left: 4vw;
}

.logo path {
  /* fill: #fff; */
  fill: #000;
}

#logo {
  z-index: 4;
  position: absolute;
}


#logo svg {
  height: 22px;
  width: auto;
  float: left;
}


#logo path {
  transition: fill 0.3s ease;
}
#logo .default path {
  fill: black;
}
#logo .fillBlack path {
  fill: black;
}
#logo .fillWhite path {
  fill: white;
}

.fillSecondary rect {
  fill: var(--swiper-theme-color)
}

.reversed {
  transform: scale(-1, 1);
}

.vermilion {
  color: var(--vermilion)
}

.menuOn #logo path {
  fill: var(--white);
}


#top.navSm {
  top: 0;
  position: fixed;
  backdrop-filter: blur(2px);
}

.menuWrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  right: 5px;
  position: fixed;
}


#signIn {
  margin: 0 0 0 20px;
  cursor: pointer;
  font-weight: 500;
}

#menuCornerBtn {
  cursor: pointer;
}

.menuBtnSq {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 3;
  cursor: pointer;
}

#menuBtn.on, #menuBtn_m.on {
  right: 0;
  top: -1px;
  position: fixed;
}

.mainMenu {
  display: flex;
  width: 30vw;
  position: fixed;
  align-items: flex-start;
  justify-content: flex-end;
  opacity: 0;
  top: 0px;
  right: 0;
  height: 100vh;
  opacity: 0;
  overflow: hidden;
}

#menu {
  display: none;
}

.menuOn #menu {
  display: flex;
  z-index: 1;
  align-items: center;
  height: 100vh;
  background: var(--black);

}

.menuOn .mainMenu  {
  height: auto;
  overflow: visible;
}

footer .mainMenu {
  opacity: 1;
  height: auto;
  flex-direction: column;
  position: relative;
  width: auto;
  top: 0;
  justify-content: flex-start;
  overflow: visible;
  float: left; 
  width: 100%;
}

footer .mainMenu h3 {
  border-top: 1px solid #fff;
  padding: 0 0 50px 0;
  font-size: 50px;
  color: #fff;
  width: 100%;
}
footer .mainMenu nav {
  width: 100%;
}

.menuOn {
  height: calc(100vh - 200px);
}

nav ul, nav li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mainMenu nav {
  width: 66vw;
  padding-left: 4vw;
}

.mainMenu nav ul {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
 
}

.mainMenu ul li {
  border-top: 1px solid #fff;
  font-weight: 500;
  font-size: 50px;
  font-size: clamp(35px,2.60vw, 50px);
  font-family: "NeueHelvetica65Med", sans-serif;
  color: #fff;
  line-height: 200%;
}

.mainMenu ul li:last-child {
  border-bottom: 1px solid #fff;
}

.mainMenu li a {
  color: #fff;
  width: 100%;
  display: block;
}

#menuCornerBtn, #menuCornerBtn_m {
  position: fixed;
  right: 0;
  top: 0;
  filter: drop-shadow(-2px 5px 8px rgba(0, 0, 0, 0.15));
  /* -webkit-filter: drop-shadow(-2px 5px 10px rgba(200, 0, 0, 0.5)); */
  /* transform: scale(0.75);
  transform-origin: top right; */

}

#dropShadow {
  /* display: none; */
  /* opacity: 0; */
}

/* #menuCornerBtn_m {
  filter: ;
} */

#menuOpen1, #menuOpen2, #menuOpen1_m, #menuOpen2_m {
  display: none;
}

.menuOn #top.none.navSm {
  background: none;
}

#content {
  padding-top: 100px;
  padding-bottom: 4vw;
}

#content.caseIndex {
  padding: 0;
}

.column {
  width: 92vw;
  padding: 0 4vw;
  float: left;
}

.column.textColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textContainer {
  max-width: 900px;
  /* padding: 10vw 0; */
}


.textContainer .flex {
  align-items: flex-start;
  padding: 8vw 0;
  min-height: auto;
}

.animSvg rect {
  opacity: 1;
}


rect.off {
  transition: opacity 0.2s ease;
  opacity: 0;
}

.ui {
  cursor: pointer;
}

.readMore {
  width: 100%;
  display: flex;
  margin: 0 0 0 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.readMoreBtn {
  font-weight: 500;
  font-family: 'NeueHelvetica65Regular', sans-serif;
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.readMoreText {
  height: 0;
  overflow: hidden;
  position: absolute;
  text-align: justify;
}


.readMoreText p {
  margin: 0 0 20px 0;
}


.readMoreText.on {
  height: auto;
  position: relative;
  overflow: visible;
  margin-top: 20px;
}

.row {
  float: left;
  width: 100%;
}


.grid {
  display: grid;
  width: 100%;
}

.grid .item img {
  width: 100%;
  height: auto;
}


.grid_70_30 {
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  gap: 50px;
}

.grid_70_30 ul {
  margin-top: 70px;
}

.grid_70_30 ul li {
  padding: 0;
  margin: 0 0 50px 0;
  font-weight: 800;
  list-style: square;
}

.grid_70_30 ul li span {
  /* font-family: "Gotham A", "Gotham B"; */
  color: var(--black);
}

.grid2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  width: 100%;
}

.grid3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  width: 100%;
}

.contactInfo .grid3 {
  gap: 100px;
}

.grid4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
}

.grid5 {
  grid-template-columns: repeat(5, 1fr);
  gap: 50px;
}

.gridItem img {
  width: 100%;
  height: auto;
}

.alignLeft {
  text-align: left;
}

.logoContainer {
  /* min-height: 10vh; */
}

.logos {
  position: absolute;

}

.logoContainer.arBox {
  padding-top: calc(400 / 1600 * 100%);

}

.logoContainer .arBoxIn {
  align-items: flex-start
}

.grid.logos .item img {
  width: 90%;
  filter: contrast(200%);
  /* opacity: 0.5; */
}

.item.borderTop {
  border-top: 2px solid var(--black);
}

.heading {
  padding-bottom: 60px;
}

.heading h2{
  padding-bottom: 20px;
  /* padding-bottom: 100px; */
}

/* Aspect Ratios */

.arBox {
  height: 0;
  overflow: hidden;
  /* background: #fafafa; */
  position: relative;
  transition: background 0.3s ease;
  background: linear-gradient(90deg, rgba(250,250,250,0.5) 0%, rgba(200,200,200,0.5) 100%);
  animation: gradient 1.25s ease-in-out infinite;
  background-size: 400% 100%;
}

.arBox.on, .heading.landing h1.arBox.on {
  background: none;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

img {
  background: none;
  transition: all 0.3s ease;
}

img.loading {
  background: grey;
}

img.auto {
  width: 100%;
  height: auto;
}

.loader {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: red;
}


.landing.heading {
  padding-bottom: 0;
  position: relative;
  /* top: -70px */
}

.heading.landing h1.arBox {
  padding-top: calc(70 / 1200 * 100%);
  background: none;
}


.landing p.arBox {
  padding-top: calc(90/700 * 100%);
  background: none;
  width: 62%;
}

#home .landing p.arBox {
  padding-top: calc(40/700 * 100%);
}

#home {
  padding-bottom: 4vw;
  float: left;
  width: 100%;
}

#home .textBlock, .aboutBanner .textInner {
  max-width: 60vw;
}

#caseIndex .landing p.arBox {
  padding-top: calc(40/700 * 100%);
}

.heading h1.arBox {
  padding-top: calc(65 / 1500 * 100%);
}

.heading h2.arBox {
  padding-top: calc(30 / 884 * 100%);
}

.grid h3.arBox {
  padding-top: calc(40 / 310 * 100%);
}

.grid p.arBox {
  padding-top: calc(120/440 * 100%);
}


.arBoxIn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  /* display: none; */
}

.grid p.arBox  .arBoxIn {
  align-items: flex-start;
}

.heading p {
  max-width: 62%;
}

.item h3 {
  max-width: 100%;
}


.learn {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 20px;
}

.learn p {
  line-height: 100%;
  width: auto;
}

.learnBox {
  display: inline-block;
  margin: 0 auto;
}

#landing {
  position: relative;
  left: 0;
  width: 100vw;
  align-items: center;
}

.banner {
  float: left;
  width: 100%;
}

#landing .banner {
  height: 75vh;  
}



.banner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeTextBlock {
  position: absolute;
  top: 12vw;
  z-index: 1;
}
.homeTextBlock h1 {

}

.homeTextBlock h1 .arBoxIn span{
  background: var(--white);
  margin: 0 20px 0 0;
  padding: 8px 16px;
  
}

.homeTextBlock .pBox {
  background: var(--white);
  padding: 16px 16px;
  
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  min-height: 50vh;
}

.flex.spaceBetween {
  flex-direction: row;
  justify-content: space-between;
}

/* About */
#aboutPage .flex {
  min-height: auto;
  padding: 6.5vw 4vw;
  aspect-ratio: auto;
  
}

.bio {
  padding: 6vw 0;
}

#bioPage .textContainer {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10vw 0;
}

#bioPage .textContainer img {
  width: 100%;
  max-width: 375px;
  height: auto;
  margin-bottom: 30px;
}

#bioPage .backBtn {
  position: fixed;
  left: 4vw;
  top: 10vw;
}

#bioPage h1 {
  font-size: clamp(25px,3.75vw, 50px);
}

/* Swiper */

.swiperColumn {
  /* margin-top: 100px; */
  float: left;
  width: 100%;

}



#home .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  box-sizing: content-box;

}

#home .swiper-slide {
  width: auto !important;
}

#home .slide {
  display: flex;
  float: left;
  /* flex-direction: column; */
  justify-content: space-between;
  /* width: calc(100% - 4vw); */
  width: auto;
  padding: 0;
  /* max-height: 760px; */
  transition: all 0.3s ease;
  overflow: hidden;
}


.quoteSlide {
  text-align: left;
}

.quoteSlide p {
  width: 90%;
}

.quoteSwiper .swiperNav {
  padding-top: 40px;
}


.squareSlides .slide {
  aspect-ratio: 1/1;
}

#home .slide img{
  width: auto;
  height: 100%;
  object-fit: cover;
  /* max-height: 800px; */
  float: left;
  opacity: 1;
  max-height: 60vh;
  transition: opacity 0.3s ease;
}

#home .slide img:hover {
  opacity: 0.8;
}

.caption {
  position: absolute;
  background: rgba(0,0,0,0.8);
  color: var(--white);
  padding: 10px 15px;
  z-index: 2;
}

.caption p {
  margin: 0;
  padding: 0;
  line-height: 100%;
  position: relative;
  top: 2px; 
}

.swiper-button-next {
  z-index: 3;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  background: rgba(16, 41, 76, 0.75);
  border: 1px solid #fff;
  border-radius: 40px;
  width: 50px !important;
  height: 50px !important;
  transition: all 0.3s ease;
  top: calc(50% - 4px) !important;
  opacity: 0;
}

 .pageReady .swiper-button-prev,
.pageReady .swiper-button-next {
  opacity: 1;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
  font-weight: 700;
}

.slide .textRow {
  padding: 15px 25px;
  height: 200px;
  float: left;
  width: calc(100% - 50px);
  transition: all 0.3s ease;

}


.slideInner {
  transition: transform 0.3s ease;
}

.squareSlides .slideInner img{
  aspect-ratio: 1/1;
}


.swiper-slide-active .textRow {
  transform: translateY(-230px);
}

.swiper-slide-active .slide img {
  opacity: 1;
}

.swiperNav {
  padding: 25px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
/* .swiperNav .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
} */

.swiperNext, .swiperPrev {
  cursor: pointer;
}

.swiperFraction.swiper-pagination-fraction {
  width: auto;
}

.swiper-button-disabled {
  opacity: 0.3;
}


/* Animations */

.animTrigger {
  position: relative;
  top: -5vh;
  /* border-top: 1px solid fuchsia; */
  height: 1px;
  width: 100vw;
  float: left;
  left: 0;
}

#row0trigger {
}

.anim0 {
  opacity: 0;
}

#landing .textBlock {
  z-index: 1;
  background: white;
}



/* Case Index */
.gridCaseIndex {
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 20px;
  /* margin-top: 60px; */
}

.grid .item.caseIndexBox {
  /* overflow: hidden; */
  grid-row: span 2;
  /* height: calc(46vw + 50px); */
  width: 100%;
  height: 100%;
  aspect-ratio: 2/1;

}

#caseIndex .arBox {
  background: none;
}

.grid .item.caseIndexBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: all 0.3s ease;
  position: absolute;
  z-index: 1;
  left: 0;
}

 .caseIndexBoxIn {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: all 0.3s ease;
 }


.caseIndex .textRow {
  padding: 0;
  height: 100%;
  float: left;
  width: 100%;
  transition: all 0.3s ease;
  /* transform: translateY(-100%); */
  background: rgba(0, 0, 0, 0.75);
  color: var(--white);
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
  position: relative;
}

.caseIndex .caseIndexBoxIn:hover .textRow{
  opacity: 1;
  /* transform: translateY(-100%); */
}



/* Case Detail */

#caseContent {
  /* margin-top: -152px; */
  margin-top: -1px;
}

#caseContent #row0.row {
  padding-top: 0;
}

.heading h2.caseHeading.arBox {
  /* padding-top: calc(200/800 * 100%); */
 
}
.caseHeading.arBox .arBoxIn {
  width: 100%;
}


.caseRow .heading {
  width: 100%;
}


.caseRow {
  width: 100%;
  height: auto;
  float: left;
}

.caseRowContainer.grid2 {
  gap: 20px;
}

.caseRowBox .swiperColumn {
  margin-top: 20px;
}

.caseRowContainer.solo {
  padding-bottom: 20px;
  float: left;
  width: 100%;
}

.caseRow img, .caseRow video {
  float: left;
  width: 100%;
  height: auto;

}

.caseRow video {
  height: 100%;
  object-fit: cover;
  aspect-ratio: 2;
}

.grid2 .caseRow video {
  aspect-ratio: 1;
}

.caseRow.arBox {
  padding-top: calc(400/800 * 100%);
  background: none;
  width: 100%;
}

.caseRow.arBox.wHeading {
  padding-top: calc(500/800 * 100%);
}


.caseRow .arBoxIn {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


h3.arBox {
  padding-top: calc(50/800 * 100%); 
}


.flex {
  display: flex;
  gap: 40px;
}


.flex.gap0 {
  gap: 0;
}

.items {
  display: grid;
}

.items1 {
  grid-template-columns: 1fr;
}

.items2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.items3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.items4 {
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.items12 {
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}


.textGrid {
  grid-template-columns: 2fr 1fr;
  gap: 40px;
}

.items img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.items video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arBoxIn video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#landing .arBox {


}

.caseRowBox {
  float: left;
  width: 100%;
  padding: 0 0;
}

.caseRowBox.sectionRow {
  padding: 250px 0 20px 0;
}

.caseRowBox.textRow {
  height: auto;
}

.textCol {
  padding: 60px 80px 80px 80px;
  width: calc(50vw - 160px);
  /* max-width: 1200px */
}

.textCol.center {
  width: 50vw;
  padding: 60px 25vw 80px 25vw;
}

.textCol.right {
  float: right;
}
/* .caseRow h2 {
  padding-bottom: 
} */

.itemArBox {
  height: 0;
  overflow: hidden;
  /* background: #fafafa; */
  position: relative;
  transition: background 0.3s ease;
  background: linear-gradient(90deg, rgba(250,250,250,0.5) 0%, rgba(200,200,200,0.5) 100%);
  animation: gradient 1.25s ease-in-out infinite;
  background-size: 400% 100%;
}

.itemArBox.loaded {
  background: none;
}

.itemArBoxIn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  /* display: none; */
}

.itemArBox img {
  background: none;
}

.caseHeadingRow .column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
}


.caseHeadingRow .heading.row0 {
  padding: 180px 0 60px 0;
  float: left;
  width: 100%;
}

#caseContent h1, #caseContent h1 p {
  /* font-size: 4.25vw; */
  font-size: clamp(28px,3.75vw, 72px);
  width: 100%;
  text-align: center;
  padding: 0;
  line-height: 110%;
  margin: 0 0 40px 0;
  font-family: "ChronicleDisp-Bold", serif;
}

#caseContent .textColumn h2 p {
  margin: 0 0 40px 0;
}

#caseContent h3.arBox {
  padding-top: calc(22/800 * 100%);
  margin-top: 0;
}

.caseHeadingRow h1 {
  font-size: clamp(16px,3.75vw, 72px);
  width: 100%;
  text-align: left;
}

.caseHeadingRow video, .caseHeadingRow img {
height: 60vh;
width: 100%;
object-fit: cover;
float: left;
}

.caseHeadingRow.column {
display: flex;
flex-direction: column;
justify-content: flex-start;
}


/* Contact */

.banner {
  height: 50vh;
}

.banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#contactPage .column {
  display: flex;
  align-items: center;
  justify-content: center;

}

.contactInfo {
  max-width: 1200px;
 
}


/* Swiper */
.swiperColumn {
  margin-top: 0;
}


.swiperCaption {
  position: relative;
  z-index: 2;
  top: 100%;
  margin-top: -64px;
  float: right;

  height: 60px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: white;
  color: var(--black);
  cursor: pointer;
}

.captionText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 0;
  padding: 0;
  overflow: hidden;
  transition: width 0.3s ease;
}

.captionText p {
  opacity: 0;

}

.plusLine {
  opacity: 1;
}

.open .plusLine {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.swiperCaption.open .captionText {
  width: 40vw;
  padding: 10px 20px;
  overflow: auto;
}

.swiperCaption.open .captionText p {
  opacity: 1;
  transition: opacity 0.3s ease 0.2s;
}

.captionBtn {
  float: right;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
 
}

.swiperCaption > * {
 pointer-events: none;
}

/* Scroll */

.caseTrigger {
  position: absolute;
}




/* WCAG 2.1 */

a.skip-main {
	left:-999px;
	position:absolute;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
	z-index:-999;
  }
  a.skip-main:focus, a.skip-main:active {
	color: #fff;
	background-color:#000;
	left: auto;
	top: auto;
	width: 30%;
	height: auto;
	overflow:auto;
	margin: 10px 35%;
	padding:5px;
	border-radius: 15px;
	border:4px solid yellow;
	text-align:center;
	font-size:1.2em;
	z-index:999;
  }




@media only screen and (max-width: 1440px) {
  .textCol {
    max-width: calc(60vw - 160px);
  }
  /* h1 {
    font-size: 90px;
  } */

}

@media only screen and (max-width: 1440px) {

  /* h1 {
    font-size: 90px;
  } */

}

@media only screen and (max-width: 900px) {
  #logo, .menuOn #logo {
    /* top: 17px */
  }
  #logo svg {
    height: 14px;
  }

  h1,
  h1 p,
  nav ul,
  nav ul li,
  #detail h1,
  .textBlock h2, .textBlock h2 p {
    font-size: 28px;
  }

  h2, h2 p {
    font-size: 25px;
  }

  h3, .item h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }


  p, .bioRow h4 {
    font-size: 14px;
  }

  .mainMenu {
    width: 100vw;
  }

#home h3 p{
    font-size: clamp(14px, 1.458vw, 28px);
    font-weight: 200;
    margin: 10px 0 10px 0;
    padding: 0;
    line-height: 120%;
    font-family: "NeueHelvetica45Light", sans-serif;
    letter-spacing: 1px;
  }
  
  #aboutPage h3, #aboutPage h3 p, .caseIndexBox h2 {
    max-width: 100%;
    font-size: 18px;
    margin: 0;
  }

  #aboutPage .gridItem h3 {
    margin: 0 0 10px 0;
  }

  #aboutPage .grid3 {
    gap: 40px;
  }


.contactInfo h1 {
  font-size: clamp(25px,3.75vw, 50px);
}

  .heading {
    padding-bottom: 40px;
  }

  .heading p, .landing p.arBox {
    max-width: 100%;
    width: 100%;
  }
  .flex {
    min-height: 25vh;
    aspect-ratio: 1/1;
  }

  .flex.flexLong {
    aspect-ratio: auto;
    padding: 100px 0;
  }

  #aboutPage .flex {
    padding: 0 4vw 125px 4vw;

  }
  #aboutPage .aboutBanner .flex{
    padding: 150px 0;
  }
  .contactInfo.flex {
    aspect-ratio: auto;
    padding: 80px 0;
  }

  .contactInfo .grid3 {
    gap: 20px;
    align-items: center;
    justify-content: center;
  }

  .contactInfo .gridItem{
    text-align: center;
  }

  .flex.readMoreOn {
    aspect-ratio: auto;
  }

  .grid3, .grid4, .grid_70_30, .mainMenu nav ul, footer {
    grid-template-columns: 100%;
  }

  /* footer {
    min-height: 100vh;

  } */

  .mainMenu nav ul {
    /* grid-template-rows: 100%; */
  }

  .grid3 {
    gap: 25px
  }

  .grid5 {
    grid-template-columns: repeat(3, 1fr);
  }

  #teamRow .grid5 {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  
  }
  
  #home .slide {
    width: 100%;
  }
  #home .swiper-slide {
    width: 100% !important;
  
  }

  #home .slide img {
    aspect-ratio: 1/1;
    height: auto;
    width:100%;
  }

  .grid .item.caseIndexBox {
    aspect-ratio: 1/1;
    grid-row:span 1;
  }

  .grid .item.caseIndexBox img {
    /* position: relative; */
    aspect-ratio: 1/1;
    height: auto;
  }

  .caseIndex .textRow {
    padding: 0;
    aspect-ratio: 3/1;
    height: auto;
    opacity: 1;
  }
  
  .caseIndex .caseIndexBoxIn {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-end;
  }
  
  .menuOn #menu {
    align-items: flex-start;
    padding-top: 150px;
  }

  .textCol {
    max-width: 92vw;
    width: 92vw;
    padding: 8vw 4vw 8vw 4vw;
  }

  .textCol.center {
    max-width: 92vw;
    width: 92vw;
    padding: 8vw 4vw 8vw 4vw;
    }

  .caseRowContainer.solo.hasText {
    padding-bottom: 0;
  }

  #bioPage .backBtn {
    top: 85px;
    position: absolute;
  }
  #bioPage .textContainer {
    padding: 125px 0;
  }
  
  /* Aspect Ratio Boxes */

  .heading.landing h1.arBox {
    padding-top: calc(40 / 360 * 100%)
  }

  #caseIndex .landing p.arBox {
    padding-top: calc(120/360 * 100%);
  }

  .heading h2.arBox {
    padding-top: calc(16/360 * 100%);
  }

  .grid h3.arBox {
    padding-top: calc(50/360 * 100%)
  }

  .grid p.arBox {
    padding-top: calc(140/360 * 100%);
  }

  #caseContent .heading h1.arBox {
    padding-top: calc(40 / 360 * 100%);
  }

  #caseContent h3.arBox {
    padding-top: calc(22/360 * 100%);
  }

  #caseContent .textColumn h2 p, #caseContent h1, #caseContent h1 p {
    margin: 0 0 25px 0;
    }


    .caption {
      left: 0;
    }
    #caseContent {
      overflow-x: hidden;
    }

    .relatedWork .flex {
      aspect-ratio: auto;
    }

    #bioPage h1 {
      height:25px;
    }
    #home .textBlock, .aboutBanner .textInner {
      max-width: 100vw;
    }

}

@media only screen and (orientation: portrait) and (max-width: 900px) {

}

@media only screen and (orientation: landscape) and (max-width: 900px) {
  #top {
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    width: 90vw;
    padding: 0 5vw;
  }

  .swiper-wrapper {
    padding: 0;
  }


}



@media only screen and (max-width: 600px) {
  nav.sub .swiper-wrapper{
    scroll-snap-type: none !important;
    padding-left: 5%;
  }

  /* Menu */

  .mainMenu {
    top: 0px;
  }

  .mainMenu nav {
    width: 50vw;
    padding-left: 0;
  }
  .mainMenu nav ul {
    grid-template-rows: auto;
  }

  .mainMenu ul li {
    font-size: 26px;
  }

  /* .menuWrap {
    height: 100px;
  } */

  #content {
    padding-top: 50px;
  }


  .gridCaseIndex {
    gap: 20px;
  }

  /* Aspect Ratios */

  h3.arBox {
    padding-top: calc(100/800 * 100%);
  }

  /* Case Detail Mobile */

  nav.sub .swiper-wrapper{
    scroll-snap-type: none !important;
    padding-left: 5%;
  }
  /* Aspect Ratios */
  

  h3.arBox {
    padding-top: calc(140/800 * 100%);
  }
  
  
  .items1 {
    gap: 40px;
  }
  .items2 {
      grid-template-columns: 1fr;
      gap: 20px;
      width: 92vw;
  }
  .textGrid {
      grid-template-columns: 1fr;
      gap: 20px;
  }
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
  }
  .grid_70_30 {
    gap: 20px;
  }
  .grid_70_30 ul {
    margin-top: 0;

  }

  .gridCaseIndex {
    grid-template-columns: 1fr;
  }

  #landing .banner, .caseHeadingRow video, .caseHeadingRow img {
    height: auto;
    aspect-ratio: 1/1;
  }


}
@media only screen and (max-width: 375px) {


}